import { Checkmark, QuestionMarkCircleOutline } from '@easy-eva-icons/react'
import * as React from 'react'
import type { PropsWithoutChildren } from '../../../types/helpers'
import { Button } from './Button'
import { PricingContext } from './PricingContext'
import { PlanType } from './utils'
import { Tag } from '../../atoms/Tag'
import { Tooltip } from '../../atoms/Tooltip'
import { ButtonProps } from './Button'
export type Feature = {
  content: string
  info?: string
}

export type ScalePlanProps = {
  name: string
  everythingFrom: string
  features: Feature[]
  ctaProps?: PropsWithoutChildren<ButtonProps>
}

export const OrganisationPlan: React.VFC<ScalePlanProps> = (props) => {
  const { features, ctaProps, name } = props

  const { currentPlan } = React.useContext(PricingContext)

  const ctaContent = React.useMemo((): string => {
    switch (currentPlan) {
      case PlanType.Organisation:
        return 'Stay on this plan'
      default:
        return 'Contact us'
    }
  }, [currentPlan])

  const showCurrentPlanTag = currentPlan === PlanType.Organisation

  return (
    <article className="border border-gray-100 rounded-xl bg-white p-8 flex flex-col">
      <div className="flex gap-3 items-top">
        <h2 className="font-bold text-2xl pb-2">{name}</h2>
        {showCurrentPlanTag && (
          <Tag className="self-center" variant="success">
            Current Plan
          </Tag>
        )}
      </div>
      <div className="pb-4 text-gray-700 max-w-72">
        Everything you need for career progression at scale
      </div>
      <div className="pb-4 flex gap-2 min-h-24 items-center">
        <h2 className="text-3xl font-bold text-gray-400">Custom</h2>
      </div>
      <ul className="list-none pl-0 flex-grow">
        <li className="flex gap-1 items-center mb-2 font-italic">
          Everything in Business, plus...
        </li>
        {features.map((feature, index) => (
          <li key={index} className="flex gap-1 items-center mb-2">
            <Checkmark className="text-green-500" />
            {feature.content}
            {feature.info && (
              <Tooltip
                content={feature.info}
                side="top"
                className="text-gray-300 cursor-pointer"
              >
                <div className="">
                  <span className="sr-only">Additional information</span>
                  <QuestionMarkCircleOutline aria-hidden />
                </div>
              </Tooltip>
            )}
          </li>
        ))}
      </ul>
      <Button variant="default" {...ctaProps}>
        {ctaContent}
      </Button>
    </article>
  )
}
