import { Checkmark, QuestionMarkCircleOutline } from '@easy-eva-icons/react'
import * as React from 'react'
import type { PropsWithoutChildren } from '../../../types/helpers'
import { Tag } from '../../atoms/Tag'
import { Button } from './Button'
import { PricingContext } from './PricingContext'
import { PlanType, Price, PricingFrequency } from './utils'
import { Tooltip } from '../../atoms/Tooltip'

import { ButtonProps } from './Button'
export type Feature = {
  content: string
  info?: string
}

export type BusinessPlanProps = {
  name: string
  price: Price
  frequency: PricingFrequency
  features: Feature[]
  ctaProps?: PropsWithoutChildren<ButtonProps>
}

export const BusinessPlan: React.VFC<BusinessPlanProps> = (props) => {
  const { price, features, ctaProps, name } = props

  const { frequency, currentPlan } = React.useContext(PricingContext)

  const ctaContent = React.useMemo((): string => {
    switch (currentPlan) {
      case PlanType.Free:
        return 'Start free trial'
      case PlanType.FreeAlreadyUsedTrial:
        return 'Add payment details'
      case PlanType.InTrial:
        return 'In trial. Add payment details'
      case PlanType.Business:
        return 'Current plan'
      case PlanType.Organisation:
        return 'Contact us'
    }
  }, [currentPlan])

  const showCurrentPlanTag = [PlanType.Business, PlanType.InTrial].includes(
    currentPlan
  )

  return (
    <article className="border border-gray-100 rounded-xl shadow-2xl bg-white p-8 flex flex-col">
      <div className="flex gap-3 items-top">
        <h2 className="font-bold text-2xl pb-2">{name}</h2>
        {showCurrentPlanTag && (
          <Tag className="self-center" variant="success">
            Current Plan
          </Tag>
        )}
      </div>
      <div className="pb-4 text-gray-700 max-w-72">
        Build and measure skill and career growth across multiple teams
      </div>
      <div className="pb-4 flex gap-2 min-h-24 items-center">
        <div className="text-6xl font-bold">
          {price && price[frequency]
            ? `$${price[frequency]}`
            : 'Price not available'}
        </div>
        <div className="flex flex-col text-gray-700 pb-2">
          <span className="">per user / per month </span>
          <span className="">Minimum 10 users</span>
        </div>
      </div>
      <ul className="list-none pl-0 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex gap-1 items-center mb-2">
            <Checkmark className="text-green-500" />
            {feature.content}
            {feature.info && (
              <Tooltip
                content={feature.info}
                side="top"
                className="text-gray-300 cursor-pointer"
              >
                <div className="">
                  <span className="sr-only">Additional information</span>
                  <QuestionMarkCircleOutline aria-hidden />
                </div>
              </Tooltip>
            )}
          </li>
        ))}
      </ul>
      <Button variant="default" {...ctaProps}>
        {ctaContent}
      </Button>
    </article>
  )
}
