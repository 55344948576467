import * as React from 'react'
import { BusinessPlan } from './BusinessPlan'
import { ButtonProps } from './Button'
import { PricingContext } from './PricingContext'
import { OrganisationPlan } from './OrganisationPlan'
import { PlanType, Price, PricingFrequency } from './utils'
import styles from './PricingPage.module.scss'
import cn from 'classnames'
export { PlanType }

export type Feature = {
  content: string
  info?: string
}

export type PricingPageProps = {
  frequency?: PricingFrequency
  onFrequencyChange?: (frequency: PricingFrequency) => void
  currentPlan: PlanType
  plans: {
    business: {
      name: string
      everythingFrom: string
      price: Price
      frequency: PricingFrequency
      features: Feature[]
      ctaProps?: ButtonProps
    }
    organisation: {
      name: string
      everythingFrom: string
      price: Price
      features: Feature[]
      ctaProps?: ButtonProps
    }
  }
}

export const PricingPage: React.VFC<PricingPageProps> = (props) => {
  const {
    frequency: initialFrequency = 'monthly',
    onFrequencyChange,
    currentPlan = PlanType.Free,
    plans: { organisation, business },
  } = props

  const [frequency, setFrequency] = React.useState(initialFrequency)

  const toggleFrequency = () => {
    const newFrequency = frequency === 'monthly' ? 'annually' : 'monthly'
    setFrequency(newFrequency)
    onFrequencyChange?.(newFrequency)
  }

  return (
    <PricingContext.Provider
      value={{ currentPlan, frequency, toggleFrequency }}
    >
      <div className="pb-8 h-full flex-1 flex flex-col">
        <header className="flex flex-col justify-center px-4 py-8 md:p-12 md:pt-16 text-center">
          <h1 className="mb-2 font-bold text-4xl md:text-5xl">
            Pay as you grow
          </h1>
          <p className="text-base mb-4 max-w-96 mx-auto">
            Whether you&apos;re just starting to write your content or ready to
            roll out company-wide, we have a plan for you.
          </p>

          <label className="flex flex-row items-center mx-auto select-none mb-5 gap-x-2 font-bold text-lg">
            Monthly
            <div className="relative w-9 h-6">
              <input
                className={cn(styles.input, 'sr-only')}
                type="checkbox"
                checked={frequency === 'annually'}
                onChange={toggleFrequency}
              />
              <div className={styles.slider} />
            </div>
            Annually
          </label>
        </header>
        <div className="self-center gap-4 grid grid-cols-2 lg:w-4/5 max-w-[1000px] px-4">
          <BusinessPlan {...business} />
          <OrganisationPlan {...organisation} />
        </div>
        <p className="text-xs text-gray-500 text-center pt-6">
          *Extra fees or user minimum may apply. Full feature list on{' '}
          <a href="https://progression.co/pricing" className="">
            our website
          </a>
          .
        </p>
      </div>
    </PricingContext.Provider>
  )
}
