import * as React from 'react'
import { PricingPage, PlanType, Feature } from 'src/design-system'
import { PricingFrequency } from '../design-system/components/pages/PricingPage/utils'

export type RailsPricingPageProps = {
  currentPlan: PlanType
  plans: {
    business: {
      name: string
      everythingFrom: string
      features: Feature[]
      price: {
        monthly: number
        annually: number
      }
    }
    organisation: {
      name: string
      everythingFrom: string
      features: Feature[]
      price: {
        annually: number
      }
    }
  }
  paths: {
    stickWithThis: string
    startFreeTrial: string
    upgradeToBusinessAnnual: string
    upgradeToBusinessMonthly: string
    contactUs: string
  }
}

export const RailsPricingPage: React.VFC<RailsPricingPageProps> = (props) => {
  const { currentPlan, plans, paths } = props

  const [frequency, setFrequency] = React.useState<PricingFrequency>('monthly')

  const onFrequencyChange = (frequency: PricingFrequency) => {
    setFrequency(frequency)
  }

  const ctaProps = () => {
    switch (currentPlan) {
      case PlanType.Business:
        return {
          business: {
            href: paths.stickWithThis,
          },
          organisation: {
            href: paths.contactUs,
          },
        }
      case PlanType.Organisation:
        return {
          business: {
            href: paths.contactUs,
          },
          organisation: {
            href: paths.stickWithThis,
          },
        }
      default:
        return {
          business: {
            href:
              frequency === 'monthly'
                ? paths.upgradeToBusinessMonthly
                : paths.upgradeToBusinessAnnual,
          },
          org: {
            href: paths.contactUs,
          },
        }
    }
  }

  return (
    <PricingPage
      frequency={frequency}
      onFrequencyChange={onFrequencyChange}
      currentPlan={currentPlan as PlanType}
      plans={{
        business: {
          name: plans.business.name,
          everythingFrom: plans.business.everythingFrom,
          features: plans.business.features,
          frequency: 'monthly',
          price: {
            annually: plans.business.price.annually,
            monthly: plans.business.price.monthly,
          },
          ctaProps: ctaProps().business,
        },
        organisation: {
          name: plans.organisation.name,
          everythingFrom: plans.organisation.everythingFrom,
          features: plans.organisation.features,
          price: {
            annually: plans.organisation.price.annually,
            monthly: 0,
          },
          ctaProps: ctaProps().org,
        },
      }}
    />
  )
}
