import * as React from 'react'
import {
  Button as BaseButton,
  ButtonOwnProps as BaseButtonProps,
} from '../../atoms/Button'
import { PolymorphicComponent } from 'react-polymorphic-box'

export type ButtonProps = {
  children?: React.ReactNode
  href?: string
} & Pick<BaseButtonProps, 'variant' | 'colourVariant'>

const defaultElement = 'a'

export const Button: PolymorphicComponent<
  ButtonProps,
  typeof defaultElement
> = (props) => {
  const { children, colourVariant = 'brand', ...restProps } = props

  return (
    <BaseButton as="a" colourVariant={colourVariant} size="lg" {...restProps}>
      {children}
    </BaseButton>
  )
}
