export type Price = {
  monthly: number
  annually: number
}

export type PricingFrequency = 'monthly' | 'annually'

export enum PlanType {
  Free = 'free',
  FreeAlreadyUsedTrial = 'freeAlreadyUsedTrial',
  InTrial = 'inTrial',
  Business = 'business',
  Organisation = 'organisation',
}
